export const CHARGE_PORT = {
  'SAE Combo': {
    image: '/img/charge-port-type/DCFast.png',
    description: 'Standard port for most electric models. Supports Level 1, Level 2, and DC Fast charging.',
  },
  CHAdeMO: {
    image: '/img/charge-port-type/CHAdeMO.png',
    description:
      'The CHAdeMO standard has been phased out for most new models. Supports Level 1, Level 2, and DC Fast charging.',
  },
  'Tesla Supercharger': {
    image: '/img/charge-port-type/Tesla.png',
    description: 'Proprietary Tesla charging standard. Supports Level 1, Level 2, and DC Fast charging.',
  },
  NACS: {
    image: '/img/charge-port-type/Tesla.png',
    description: 'North American Charging Standard (NACS). Supports Level 1, Level 2 and DC fast charging.',
  },
};
