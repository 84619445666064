import React, { Fragment } from 'react';
import { breakpoints } from 'client/utils/bootstrap';

export const VIDEO_TYPE = 'video';
export const PHOTO_TYPE = 'photo';
export const SPIN_TYPE = 'spin';
export const ALT_MEDIA = 'alt_media';
export const ALL_LABEL_VALUE = 'All photos';
export const ALL_MOBILE_LABEL_VALUE = 'All';
export const VIDEO_LABEL_VALUE = 'videos';
export const PHOTO_LABEL_VALUE = 'photos';
export const AD_UNIT_TYPE = 'adUnit';
export const AD_UNIT_TYPE_2 = 'adUnit 2';
// All category name
export const ALL_CAT_NAME = 'all';

const PHOTOS_360_DISPLAY_LABEL = (
  <Fragment>
    <i className="icon-360-arrow size-16 me-0_25" aria-hidden /> 360&deg;
  </Fragment>
);

export const CATEGORIES_PLAIN = {
  VIDEO: 'video',
  EXTERIOR: 'exterior',
  INTERIOR: 'interior',
  COLORS: 'colors',
  PHOTOS_360: '360',
};

export const MEDIA_CATEGORIES = [
  {
    label: ALL_CAT_NAME,
    displayLabel: ALL_LABEL_VALUE,
    mobileLabel: ALL_MOBILE_LABEL_VALUE,
    mediaType: PHOTO_TYPE,
    totalCount: 0,
  },
  {
    label: CATEGORIES_PLAIN.PHOTOS_360,
    displayLabel: PHOTOS_360_DISPLAY_LABEL,
    mediaType: PHOTO_TYPE,
    totalCount: 0,
  },
  {
    label: CATEGORIES_PLAIN.EXTERIOR,
    mediaType: PHOTO_TYPE,
    totalCount: 0,
  },
  {
    label: CATEGORIES_PLAIN.INTERIOR,
    mediaType: PHOTO_TYPE,
    totalCount: 0,
  },
  {
    label: CATEGORIES_PLAIN.COLORS,
    mediaType: PHOTO_TYPE,
    totalCount: 0,
  },
  {
    label: CATEGORIES_PLAIN.VIDEO,
    displayLabel: VIDEO_LABEL_VALUE,
    mediaType: VIDEO_TYPE,
    totalCount: 0,
  },
];
export const ALT_MEDIA_CATEGORIES = [
  {
    key: PHOTO_TYPE,
    label: ALL_CAT_NAME,
    displayLabel: ALL_LABEL_VALUE,
    mediaType: PHOTO_TYPE,
    totalCount: 0,
  },
  {
    key: VIDEO_TYPE,
    label: CATEGORIES_PLAIN.VIDEO,
    displayLabel: VIDEO_LABEL_VALUE,
    mediaType: VIDEO_TYPE,
    totalCount: 0,
  },
];

export const CATEGORIES = {
  [PHOTO_TYPE]: [
    {
      label: ALL_CAT_NAME,
      totalCount: 0,
    },
    {
      label: CATEGORIES_PLAIN.PHOTOS_360,
      displayLabel: PHOTOS_360_DISPLAY_LABEL,
      totalCount: 0,
    },
    {
      label: CATEGORIES_PLAIN.EXTERIOR,
      totalCount: 0,
    },
    {
      label: CATEGORIES_PLAIN.INTERIOR,
      totalCount: 0,
    },
    {
      label: CATEGORIES_PLAIN.COLORS,
      totalCount: 0,
    },
  ],
  [VIDEO_TYPE]: [
    {
      label: ALL_CAT_NAME,
      totalCount: 0,
    },
  ],
  [ALT_MEDIA]: [
    {
      label: ALL_CAT_NAME,
      totalCount: 0,
    },
    {
      label: CATEGORIES_PLAIN.PHOTOS_360,
      displayLabel: PHOTOS_360_DISPLAY_LABEL,
      totalCount: 0,
    },
  ],
};

export const LINKS_TO_CATEGORY_MAP = {
  videos: CATEGORIES_PLAIN.VIDEO,
  photos: CATEGORIES_PLAIN.EXTERIOR,
};
// first category to load by default when current category is All
export const FIRST_CATEGORY_TO_LOAD = {
  [PHOTO_TYPE]: CATEGORIES[PHOTO_TYPE][2].label,
  [VIDEO_TYPE]: CATEGORIES[VIDEO_TYPE][0].label,
};
export const LOAD_MORE_THRESHOLD = 5; // threshold for checking if need to make api call to get slides
export const SLIDES_TO_SHOW_MD = 3; // number of slides to show in filmstrip
export const SLIDES_TO_SHOW_MOBILE = 3.5; // number of slides to show in filmstrip
export const SLIDES_TO_SHOW_LG = 5; // number of slides to show in filmstrip
export const SLIDES_TO_SHOW_LG_V2 = 4.5; // number of slides to show in filmstrip for V2 PhotoFlipper, wide viewport
export const SLIDES_TO_SHOW_LG_V2_360 = 4; // number of slides to show in filmstrip for V2 PhotoFlipper, wide viewport
export const SLIDES_TO_SHOW_LG_WIDE = 3; // number of slides to show in filmstrip for PhotoFlipper, wide viewport
export const SLIDES_TO_SCROLL = 1; // how much slides should be scrolled when advance filmstrip
export const SLIDES_TO_SCROLL_MOBILE = 3; // how much slides should be scrolled when advance filmstrip
export const SLIDES_TO_SCROLL_SM = 2; // how much slides should be scrolled

export const SLIDES_TO_SHOW_SM_ALT = 2.5;
export const SLIDES_TO_SHOW_MD_ALT = 4.5;
export const SLIDES_TO_SHOW_LG_ALT = 6;

const CONTENT_TYPE = 'photo';

export const SLIDE_TO_SHOW = {
  true: SLIDES_TO_SHOW_SM_ALT,
  false: SLIDES_TO_SHOW_MD_ALT,
};

export const CAROUSEL_OPTIONS = {
  contentType: CONTENT_TYPE,
  useCSS: false,
  lazyLoad: true,
  slidesToScroll: SLIDES_TO_SCROLL,
};

export const MOBILE_CAROUSEL_OPTIONS = {
  contentType: CONTENT_TYPE,
  useCSS: true,
  lazyLoad: false,
};

export const CORE_CAROUSEL_OPTIONS = {
  lazyLoad: false,
};

export const RESPONSIVE_OPTIONS = [
  { breakpoint: breakpoints.lg, settings: { ...CAROUSEL_OPTIONS, slidesToShow: SLIDES_TO_SHOW_MD } },
  { breakpoint: breakpoints.max, settings: { ...CAROUSEL_OPTIONS, slidesToShow: SLIDES_TO_SHOW_LG_V2 } },
];
export const RESPONSIVE_OPTIONS_360 = [
  { breakpoint: breakpoints.lg, settings: { ...CAROUSEL_OPTIONS, slidesToShow: SLIDES_TO_SHOW_MD } },
  { breakpoint: breakpoints.max, settings: { ...CAROUSEL_OPTIONS, slidesToShow: SLIDES_TO_SHOW_LG_V2_360 } },
];
export const RESPONSIVE_OPTIONS_ALT = [
  { breakpoint: breakpoints.lg, settings: { ...CAROUSEL_OPTIONS, slidesToShow: SLIDES_TO_SHOW_MD_ALT } },
  { breakpoint: breakpoints.max, settings: { ...CAROUSEL_OPTIONS, slidesToShow: SLIDES_TO_SHOW_LG_ALT } },
];
export const RESPONSIVE_OPTIONS_CORE = [
  {
    breakpoint: breakpoints.lg,
    settings: { ...CAROUSEL_OPTIONS, ...CORE_CAROUSEL_OPTIONS, slidesToShow: SLIDES_TO_SHOW_MD_ALT },
  },
  {
    breakpoint: breakpoints.max,
    settings: { ...CAROUSEL_OPTIONS, ...CORE_CAROUSEL_OPTIONS, slidesToShow: SLIDES_TO_SHOW_LG_ALT },
  },
];

export const YEAR_S3_DISABLED = 2011;
export const SLIDE_INDEX_360 = 0;

export const EXTERIOR_SHOT_TYPES = ['F', 'R', 'FQ', 'RQ', 'S', 'E', 'CARGO', 'EXM', 'FOG', 'TIRE'];
export const INTERIOR_SHOT_TYPES = ['D', 'I', 'RI', 'CC', 'G', 'SCRN', 'SWD', 'DETAIL', 'SUN'];

export const TAB_PHOTOS = 'Photos';
export const TAB_360 = '360';
export const TAB_EXTERIOR = 'Exterior';
export const TAB_INTERIOR = 'Interior';

export const ALL_PHOTOS_TAB_GROUP = 'ALL_PHOTOS_TAB_GROUP';
export const EXTERIOR_PHOTOS_TAB_GROUP = 'EXTERIOR_PHOTOS_TAB_GROUP';
export const INTERIOR_PHOTOS_TAB_GROUP = 'INTERIOR_PHOTOS_TAB_GROUP';

export const TABS_GROUP = {
  [TAB_360]: ALL_PHOTOS_TAB_GROUP,
  [TAB_PHOTOS]: ALL_PHOTOS_TAB_GROUP,
  [TAB_EXTERIOR]: EXTERIOR_PHOTOS_TAB_GROUP,
  [TAB_INTERIOR]: INTERIOR_PHOTOS_TAB_GROUP,
};
